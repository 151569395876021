import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import Web3 from 'web3'
import ERC721_ABI from '../abi/abi.json'
import guardiansimg from '../assets/images/guardians.png'
import furiesimg from '../assets/images/furies.png'

function Collection() {
    const [walletData, setWalletData] = useState([])
    const [guardianBalance, setGuardianBalance] = useState(0)
    const [furieBalance, setfurieBalance] = useState(0)
    const [isCorrectNetwork, setIsCorrectNetwork] = useState(false)
    
    useEffect( () => {
        let processing = true
        axiosFetchData(processing)
        return () => {
            processing = false
        }
    },[])

    const axiosFetchData = async(processing) => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/wallets`, { withCredentials: true })
        .then(res => {
            if (processing) {
                setWalletData(res.data)
            }
        })
        .catch(err => console.log(err))
    }

    const fetchNFTs = useCallback(async () => {
        setGuardianBalance(0)
        setfurieBalance(0)
        if (window.ethereum) {
            const networkVersion = window.ethereum.networkVersion
            const correctNetwork = '1'
            if (networkVersion === correctNetwork) {
                const web3 = new Web3(window.ethereum)
                console.log("Web3 instantiated", web3)
                setIsCorrectNetwork(true)

                for (const wallet of walletData) {
                    console.log("Processing wallet", wallet);
                    const guardianContract = new web3.eth.Contract(ERC721_ABI, '0x9a3611Cc9654B8eFcF458Ac6d79889aa0A30ca57')
                    const furieContract = new web3.eth.Contract(ERC721_ABI, '0xaa75F0ab6662B68228e7039b461bAB80DE4408FA')

                    if (wallet.wallet1){
                        const guardianBalanceOf1 = await guardianContract.methods.balanceOf(wallet.wallet1).call()
                        setGuardianBalance(prev => prev + Number(guardianBalanceOf1))
                        console.log(`Balance of ${wallet.wallet1} in guardianContract is ${guardianBalanceOf1}`)
                        const furieBalanceOf1 = await furieContract.methods.balanceOf(wallet.wallet1).call()
                        setfurieBalance(prev => prev + Number(furieBalanceOf1))
                        console.log(`Balance of ${wallet.wallet1} in furieContract is ${furieBalanceOf1}`)
                    }
                    if (wallet.wallet2){
                        const guardianBalanceOf2 = await guardianContract.methods.balanceOf(wallet.wallet2).call()
                        setGuardianBalance(prev => prev + Number(guardianBalanceOf2))
                        const furieBalanceOf2 = await furieContract.methods.balanceOf(wallet.wallet2).call()
                        setfurieBalance(prev => prev + Number(furieBalanceOf2))
                    }
                    if (wallet.wallet3){
                        const guardianBalanceOf3 = await guardianContract.methods.balanceOf(wallet.wallet3).call()
                        setGuardianBalance(prev => prev + Number(guardianBalanceOf3))
                        const furieBalanceOf3 = await furieContract.methods.balanceOf(wallet.wallet3).call()
                        setfurieBalance(prev => prev + Number(furieBalanceOf3))
                    }

                }

            } else {
                console.log("Please connect to the correct network")
                setIsCorrectNetwork(false)
            }


        } else {
            console.log("Please connect with MetaMask")
            setIsCorrectNetwork(false)
        }
    }, [walletData]);

    useEffect(() => {
        fetchNFTs();
    }, [fetchNFTs, walletData])

    return (
        <div className="page-container">
            <h1>Your Collection</h1>
            {isCorrectNetwork ? 
                <div className="nft-display">
                    <div className="nft-card">
                        <img src={guardiansimg} alt="Guardians" />
                        <h2>Guardians</h2>
                        <p>You own: {guardianBalance || '0'} Guardians</p>
                        <div className="buy-links">
                            <a href="https://opensea.io/collection/uldorguardians" target="_blank" rel="noopener noreferrer">Buy on OpenSea</a>
                            <a href="https://looksrare.org/collections/0x9a3611cc9654b8efcf458ac6d79889aa0a30ca57" target="_blank" rel="noopener noreferrer">Buy on LooksRare</a>
                        </div>
                    </div>
                    <div className="nft-card">
                        <img src={furiesimg} alt="Furies" />
                        <h2>Furies</h2>
                        <p>You own: {furieBalance || '0'} Furies</p>
                        <div className="buy-links">
                            <a href="https://opensea.io/collection/uldorfuries" target="_blank" rel="noopener noreferrer">Buy on OpenSea</a>
                            <a href="https://looksrare.org/collections/0xaa75f0ab6662b68228e7039b461bab80de4408fa" target="_blank" rel="noopener noreferrer">Buy on LooksRare</a>
                        </div>
                    </div>
                </div>
                :
                <p>Please connect to Ethereum Mainnet</p>
            }
        </div>
    );
}

export default Collection;
