import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Web3 from 'web3'

function Settings({ displayName }) {
    const [newDisplayName, setNewDisplayName] = useState(displayName);
    const [editMode, setEditMode] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [account, setAccount] = useState('');
    const [walletData, setWalletData] = useState([])
  
    useEffect(() => {
      setNewDisplayName(displayName);
    }, [displayName]);

    useEffect( () => {
        let processing = true
        axiosFetchData(processing)
        return () => {
            processing = false
        }
    },[])

    const axiosFetchData = async(processing) => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/wallets`, { withCredentials: true })
        .then(res => {
            if (processing) {
                setWalletData(res.data)
            }
        })
        .catch(err => console.log(err))
    }

    const unlinkWallet = async (walletToDelete) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/unlinkWallet`, {
                walletAddress: walletToDelete
            }, { withCredentials: true });
        
            if (response.status === 200) {
                // alert('Wallet deleted successfully');
                // Refresh wallet data
                axiosFetchData(true);
            }
        } catch (error) {
            console.error("Failed to delete wallet: ", error);
        }
    };

    const addWallet = async () => {
        // Check if Ethereum provider is injected
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            console.log(web3)
            try {
                // Request account access
                const accounts = await window.ethereum.enable();
                setAccount(accounts[0]);

                // Send the new wallet address to the server
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addWallet`, {
                    walletAddress: accounts[0],
                }, { withCredentials: true });

                if (response.status === 200) {
                    //alert('Wallet added successfully');
                    window.location.reload()
                }
            } catch (error) {
                console.error("Failed to add wallet: ", error);
            }
        }
        // If no injected web3 instance, you're out of luck
        else {
            console.log('Non-Ethereum browser detected. Consider trying MetaMask!');
        }
    };

    const handleDisplayNameChange = async (event) => {
      event.preventDefault();
  
        // Check if the display name only contains alphanumeric characters
        const alphanumericRegex = /^[a-zA-Z0-9]+$/
        if (!alphanumericRegex.test(newDisplayName)) {
            setValidationError('Display name can only contain letters, numbers, and spaces')
            return
        }
        // Check if the display name is more than 20 characters
        if (newDisplayName.length > 20) {
            setValidationError('Display name cannot be more than 20 characters');
            return;
        }
        setValidationError('') // Reset error message before API call
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/changeDisplayName`, {
            displayName: newDisplayName,
            }, { withCredentials: true })
    
            if (response.status === 200) {
                //alert(response.data.message);
                setEditMode(false);
                window.location.reload()
            }
        } catch (error) {
            console.log('Error changing display name:', error.message);
            setValidationError('Error, please try another name')
        }
    };
    const handlePasswordReset = async () => {
        try {
            // Replace this URL with your password reset endpoint
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/resetPassword`, {}, { withCredentials: true });

            if (response.status === 200) {
                setPasswordResetSent(true);
            } else {
                // Handle non-200 responses if needed
            }
        } catch (error) {
            console.log('Error sending password reset:', error.message);
            // Handle error if needed
        }
    };

    return (
        <div className="page-container">
            <h1>Account Settings</h1>
            <div className="settings-container">
                <div className="setting-item">
                        <label className="setting-label">Profile Name</label>
                        {editMode ? (
                            <form className="setting-form" onSubmit={handleDisplayNameChange}>
                                <input type="text" value={newDisplayName} onChange={(e) => setNewDisplayName(e.target.value)} required />
                                <button className="setting-button" type="submit">Change</button>
                            </form>
                        ) : (
                            <div className="setting-display">
                                <span>{displayName}</span>
                                <button className="setting-button" onClick={() => setEditMode(true)}>Edit</button>
                            </div>
                        )}
                        {validationError && <p className="validation-error">{validationError}</p>}
                    </div>
            </div>
            <div className="settings-container">

                <div className="setting-item">
                    <label className="setting-label">Password</label><br />
                    {passwordResetSent ?
                        <p>Password reset instructions have been sent to your email address.</p>
                        :
                        <button className="setting-button" onClick={handlePasswordReset}>Change Password</button>
                    }
                </div>
            </div>
            <div className="settings-container">
                <div className="setting-item">
                    <label className="setting-label">Wallets</label>
                    <div className="wallets-container">
                        {walletData?.map((item, index) => (
                            <div key={index} className="wallet-item">
                                {item.wallet1 && <div>{item.wallet1} <button className="wallet-button" onClick={() => unlinkWallet(item.wallet1)}>Unlink</button></div>}
                                {item.wallet2 && <div>{item.wallet2} <button className="wallet-button" onClick={() => unlinkWallet(item.wallet2)}>Unlink</button></div>}
                                {item.wallet3 && <div>{item.wallet3} <button className="wallet-button" onClick={() => unlinkWallet(item.wallet3)}>Unlink</button></div>}
                            </div>
                        ))}
                        {(walletData.length === 0 || walletData.some(item => !item.wallet1 || !item.wallet2 || !item.wallet3)) 
                            && <button className="setting-button" onClick={addWallet}>Link Wallet</button>
                        }
                    </div>
                </div>
            
                {account && <div className="connected-account">Connected Account: {account}</div>}
            </div>
        </div>
    );
}

export default Settings;