import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import dreadarenabox from '../assets/images/DreadArena.png'
import elixirlogo from '../assets/images/elixir.webp'
import hyperplaylogo from '../assets/images/HyperPlay.jpg'
import seddonalogo from '../assets/images/Seddona.svg'

export default function Home() {
    const [tournaments, setTournaments] = useState([])
    const sliderRef = useRef(null)
    const [currentSlide, setCurrentSlide] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                `${process.env.REACT_APP_BACKEND_URL}/api/featured-tournaments`
            );
            setTournaments(result.data.featured);
        };
        fetchData();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next)
    };    

    return (
        <div className="page-container">
            <h1>Featured Tournaments</h1>
            <div className="slider-container">
                <div className="slider-content">
                    <Slider ref={sliderRef} {...settings}>
                        {tournaments.map((tournament) => (
                            <div key={tournament.access_code}>
                                <div className="slideContent">
                                    <h2>{tournament.tourney_name}</h2>
                                    <img src={tournament.bg_img} alt={tournament.tourney_name} className="slide-image" />
                                    <button className="viewDetailsButton" onClick={() => window.open(tournament.details_url, "_blank")}>View Details</button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className="slider-tabs">
                        {tournaments.map((tournament, index) => (
                            <div className={index === currentSlide ? "tab active-tab" : "tab"} key={tournament.access_code} onClick={() => sliderRef.current.slickGoTo(index)}>
                                {tournament.tourney_name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <h3>Find Uldor Dread Arena on these amazing launchers!</h3>
            <div className="gameInfo">
                <img src={dreadarenabox} alt="Uldor Dread Arena" className="boxArt" />
                <div className="launcherLogos">
                    <a href="https://launcher.elixir.app/games/uldor" target="_blank" rel="noreferrer"><img src={elixirlogo} alt="Elixir" className="logo" /></a>
                    <a href="https://store.hyperplay.xyz/game/uldordreadarena" target="_blank" rel="noreferrer"><img src={hyperplaylogo} alt="HyperPlay" className="logo" /></a>
                    <a href="https://www.seddona.com/games/1" target="_blank" rel="noreferrer"><img src={seddonalogo} alt="Seddona" className="logo" /></a>
                </div>
            </div>
        </div>
    );
}