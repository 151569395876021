import React, { useState } from 'react';
import axios from 'axios';

function LoginForm({ onLogin }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (forgotPassword) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgotPassword`, { email }, { withCredentials: true })


          if (response.status === 200) {
            setForgotPasswordMessage("Please check your email to continue.")
            setErrorMessage('')
          }
        } catch (error) {
          setErrorMessage('Reset password failed')
        }
      } else {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
            email,
            password,
          }, { withCredentials: true });

          console.log('Login response:', response.data);
    
          if (response.status === 200) {
            setErrorMessage('')
            onLogin(); // Calls the onLogin prop
          }
        } catch (error) {
          setErrorMessage('Login failed')
          setForgotPassword(true);
        }
      }
    };
  
    const handleBack = () => {
      setForgotPassword(false)
      setForgotPasswordMessage(false)
      setErrorMessage('')
    };

    return (
      <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
              <label>
                  Email:
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </label>
          </div>

          {!forgotPassword && (
            <div className="form-group">
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </label>
            </div>
          )}

          {forgotPassword && <p style={{ textAlign: "center" }}>{forgotPasswordMessage}</p>}

          { !forgotPasswordMessage && (
            <button type="submit" className="submit-button">{forgotPassword ? 'Reset Password' : 'Login'}</button>
          )}
          
          {errorMessage && (
            <p style={{ textAlign: "center", color: 'red' }}>{errorMessage}</p>
          )}

          {forgotPassword && <p style={{ textAlign: "center" }}><a href="#" onClick={handleBack}>Back</a></p>}

          {!forgotPassword && (
            <p style={{ textAlign: "center" }}><a href="#" onClick={(e) => {e.preventDefault(); setForgotPassword(true)}}>Forgot Password?</a></p>
          )}

      </form>
    );
  }
  
  export default LoginForm;
