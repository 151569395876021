import maxlevellogo from '../assets/images/maxlevelstudios.png'
import { FaTwitter, FaMediumM, FaDiscord, FaYoutube } from "react-icons/fa";

function Footer() {
    return (
        <div className="footer">
            <div className='content-wrapper'>
            <a href="https://maxlevelstudios.com/" target="_blank" rel="noreferrer"><img className="studiologo" src={maxlevellogo} alt="logo" /></a>
                <div className="footer-icons">
                    <a href="https://twitter.com/uldorgame" data-icon="twitter" target="_blank" rel="noreferrer"><FaTwitter /></a>
                    <a href="https://medium.com/@uldor" data-icon="medium" target="_blank" rel="noreferrer"><FaMediumM /></a>
                    <a href="https://discord.gg/uldor" data-icon="discord" target="_blank" rel="noreferrer"><FaDiscord /></a>
                    <a href="https://www.youtube.com/@uldorgame" data-icon="youtube" target="_blank" rel="noreferrer"><FaYoutube /></a>
                </div>
                <div className="footer-text">Copyright &copy; 2023 Max Level Studios | <a href="https://uldor.com/tos/" target="_blank" rel="noreferrer">Terms of Service</a> | <a href="https://uldor.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a></div>
            </div>
        </div>
    )
}

export default Footer