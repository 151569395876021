import React, { useState } from 'react'
import './App.css'
import Router from './components/Router'
import Context from './components/Context'

function App() {
  const [displayName, setDisplayName] = useState('Anonymous');

  const userInfo = {
    name: "Johnny",
    email: "codrkai@gamil.com",
    loggedIn: true,
    cartItems: 4,
    displayName, // this adds displayName to the userInfo context
    setDisplayName // this allows other components to update displayName
  }

  return (
    <>
      <Context.Provider value={userInfo}>
        <Router />
      </Context.Provider>
    </>
  );
}

export default App;
