import React, { useState, useEffect } from 'react';
import LoginForm from './Login';
import Header from './Header'
import Footer from './Footer'
import Home from '../pages/Home'
import Contact from '../pages/Contact'
import Collection from '../pages/Collection'
import Settings from '../pages/Settings'
import ProductDetails from '../pages/ProductDetails'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import axios from 'axios';
import { useContext } from 'react'
import Context from './Context'

export default function Router() {
    const userData = useContext(Context)
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkSession = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/session`, { withCredentials: true })
            console.log('Session check response:', response.data)
            setIsLoggedIn(response.data.loggedIn)
            setDisplayName(response.data.displayName)
            userData.displayName = response.data.displayName
          } catch (error) {
            console.log('Error checking session', error);
          } finally {
            setLoading(false);
          }
        };
      
        checkSession();
      }, [isLoggedIn, userData]); // Add isLoggedIn as a dependency

    // Define a style that transitions the opacity
    const loadedStyle = {
        opacity: loading ? 0 : 1,
        transition: 'opacity 0.5s',
    }

    if (loading) {
        return (
            <div style={loadedStyle}>
                Loading
            </div>
        )
    }
      

    const Layout = () => {
        return (
            <>
                <Header onLogout={onLogout} />
                <div className='content-wrapper'>
                {isLoggedIn ? (
                    <div className="content"><Outlet /></div>
                ) : (
                    <LoginForm onLogin={() => setIsLoggedIn(true)} />
                )}
                </div>
                <Footer />
            </>
        );
    };

    const onLogout = async () => {
        console.log('doinglogout')
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`, { withCredentials: true });
    
            if (response.status === 200) {
                console.log('Logged out')
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.log('Error logging out', error);
        }
    };

    const BrowserRoutes = () => {
        return (
            <BrowserRouter>
                <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="contact-us" element={<Contact />} />
                    <Route path="collection" element={<Collection />} />
                    <Route path="settings" element={<Settings displayName={displayName} />} />
                    <Route path="product" element={<ProductDetails />} />
                    <Route path="product/:id" element={<ProductDetails />} />
                </Route>
                </Routes>
            </BrowserRouter>
        )
    }

    return (
        <BrowserRoutes />
    )
}
