import logo from '../assets/images/logo.png'
import { useContext, useState, useEffect, useRef } from 'react'
import Context from './Context'

export default function Header({ onLogout }) {
    const userData = useContext(Context)
    const [isMenuOpen, setMenuOpen] = useState(false)
    const node = useRef()

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    }
    const handleClick = e => {
        if (node.current.contains(e.target)) {
          // inside click
          return;
        }
        // outside click 
        setMenuOpen(false);
    };



    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <nav className="nav-bar">
            <div className="nav-wrapper" ref={node}>
                <a className="nav-logo" href="/"><img className="nav-logo" src={logo} alt="logo" /></a>
                {userData.displayName ? (
                    <>
                    <button onClick={toggleMenu} className={`nav-menu-btn ${isMenuOpen ? 'hidden' : ''}`}>☰</button>
                    <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                        <button onClick={toggleMenu} className={`nav-menu-btn ${isMenuOpen ? '' : 'hidden'}`}>X</button>
                        <li><a onClick={toggleMenu} href="/">Home</a></li>
                        <li><a onClick={toggleMenu} href="/collection">Collection</a></li>
                        <li>Logged in as <a onClick={toggleMenu} href="/settings">{userData.displayName}</a></li>
                        <li><a href="/" onClick={ () => { onLogout(); toggleMenu(); }}>Logout</a></li>
                    </ul>
                    </>
                ) : (
                    <>
                    Please log in below
                    </>
                )}
            </div>
        </nav>
    )

}
